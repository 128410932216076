import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout/Layout';
import SEO from '../components/seo';
import colors from '../components/_colors.scss';

const BlogPage = styled.main`
	display: flex;
	flex-direction: column;

	@media (min-width: 1024px) {
		flex-direction: row;
		height: 100%;
	}
`;

const Section = styled.section`
	&.title {
		padding: 20px;
	}
	&.list {
		border-top: 1px solid;
	}
	@media (min-width: 1024px) {
		width: 50%;
		height: calc(100vh - 226px);
		&.title {
			padding: 100px 50px;
		}
		&.list {
			border-top: none;
			border-left: 1px solid;
			overflow-y: scroll;
		}
	}
`;

const PageTitle = styled.h2`
	font-size: 24px;

	@media (min-width: 1024px) {
		font-size: 2.6666666667em;
	}
`;

const Blog = styled.article`
	padding: 20px;
	border-bottom: 1px solid;

	@media (min-width: 1024px) {
		padding: 50px 25px;
	}
`;

const BlogLink = styled(Link)`
	color: inherit;
	text-decoration: none;
	@media (min-width: 1024px) {
	}
`;

const BlogTitle = styled.h3`
	font-size: 20px;

	@media (min-width: 1024px) {
		font-size: 2em;
	}
`;

const BlogSubtitle = styled.h4`
	font-size: 16px;
	padding-top: 0.5em;

	@media (min-width: 1024px) {
		font-size: 24px;
		padding-top: 0.5em;
	}
`;

const Year = styled.p`
	padding: 0;
	color: ${colors.darkGreen};
	margin-top: 10px;

	@media (min-width: 1024px) {
		margin-top: 10px;
		max-width: 75ch;
	}
`;

const BlogListPage = () => {
	const data = useStaticQuery(graphql`
		{
			allSanityPost(filter: { slug: { current: { ne: null } } }) {
				nodes {
					title
					subtitle
					slug {
						current
					}
					_createdAt
				}
			}
		}
	`);
	const [posts] = useState(data.allSanityPost.nodes);
	return (
		<React.Fragment>
			<SEO title='Blog' />
			<BlogPage>
				<Section className='title'>
					<PageTitle>Articles</PageTitle>
				</Section>
				<Section className='list'>
					{posts.length > 0 && (
						<React.Fragment>
							{posts.map((post, index) => (
								<Blog key={index}>
									<BlogLink to={post.slug.current.toLowerCase()}>
										<BlogTitle>{post.title}</BlogTitle>
										{post.subtitle && <BlogSubtitle>{post.subtitle}</BlogSubtitle>}
										<Year>{post._createdAt.split('-')[0]}</Year>
									</BlogLink>
								</Blog>
							))}
						</React.Fragment>
					)}
				</Section>
			</BlogPage>
		</React.Fragment>
	)
};

export default BlogListPage;
